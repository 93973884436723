import React, { useEffect, useState } from 'react';
import './ReportDepositPaymentComponent.scss';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useStateSelector } from '../store/selectors';
import { WalletReportType } from '../wallet/models/ReportTypeEnum';
import {
    PaymentDialog,
    PriceItem,
} from '../components/PaymentDialog/PaymentDialog';
import createNotification from '../utils/createNotification';
import { PaymentMaxPollCount } from '../utils/constants';
import Helpers from '../utils/helper';

interface ReportDepositPaymentComponentProps {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    paymentSuccess: () => void;
    type: WalletReportType;
}

const ReportDepositPaymentComponent = (
    props: ReportDepositPaymentComponentProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const pricing = useStateSelector((state) => state.pricing.pricing);
    const summary = useStateSelector((state) => state.userSummary.summary);
    const stripe = useStripe();
    const elements = useElements();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [appliedCredits, setAppliedCredits] = useState(0);
    const [isPaymentInProcess, setIsPaymentInProcess] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalServicePrice, setOriginalServicePrice] = useState(0);

    useEffect(() => {
        let productPrice = '0';
        switch (props.type) {
            case WalletReportType.CAPVAR: {
                productPrice = Helpers.getCapvarDepositPrice(summary.hasPaidSubscription, pricing);
                break;
            }
        }

        setOriginalServicePrice(Number(productPrice));
        setTotalPrice(Number(productPrice));
    }, [pricing]);

    const calculateTotalPrice = () => originalServicePrice - appliedCredits;

    useEffect(() => {
        setTotalPrice(calculateTotalPrice());
    }, [appliedCredits, originalServicePrice]);

    const directPayment = () => {
        payReportDeposit(false);
    };

    const checkIfTransactionSucceeded = (
        transactionId: string,
        pollCount = 1
    ) => {
        if (pollCount <= PaymentMaxPollCount) {
            axios
                .get(`/api/wallet/transactions/${transactionId}/date`)
                .then((response: any) => {
                    if (response.data) {
                        props.paymentSuccess();
                    } else {
                        setTimeout(() => {
                            checkIfTransactionSucceeded(
                                transactionId,
                                pollCount + 1
                            );
                        }, 2000);
                    }
                })
                .catch(() => {
                    createNotification(
                        'Error occured on transaction confirmation',
                        'error'
                    );
                });
        } else {
            createNotification(
                'Could not verify the payment, please be patient. Try again later',
                'error'
            );
            props.setIsVisible(false);
        }
    };

    const payReportDeposit = (
        shouldUseSavedPaymentMethod: boolean,
        paymentTokenId: string = ''
    ) => {
        const pracId = localStorage.getItem('currentPracticeId');
        setIsPaymentInProcess(true);
        axios
            .post('/api/wallet/payReportDeposit', {
                walletId: 0,
                depositAmount: originalServicePrice,
                reportType: props.type,
                UseSavedPaymentMethod: shouldUseSavedPaymentMethod,
                PaymentSource: paymentTokenId,
                walletAmount: appliedCredits,
                practiceId: pracId,
            })
            .then((response: any) => {
                if (response?.response?.status === 402) {
                    createNotification(
                        response.response.data?.title ??
                            'An unexpected error occured. Please try again later',
                        'error'
                    );

                    setIsPaymentInProcess(false);
                    props.setIsVisible(false);
                } else {
                    handlePricingUpdatedError(response);
                    checkIfTransactionSucceeded(response.data);
                }
            });
    };

    const handlePricingUpdatedError = (response: any) => {
        if (response.response?.status === 422) {
            createNotification(
                'Report price has been changed, please try again',
                'error'
            );
            setIsPaymentInProcess(false);
            props.setIsVisible(false);
            throw new Error('Price changed');
        }
    };

    const mapPriceList = () => {
        const priceList: PriceItem[] = [];
        priceList.push({
            priceType: 'summary',
            label: 'Payment Due:',
            value: originalServicePrice,
            valueType: 'currency',
        });

        return priceList;
    };

    return (
        <div className="report-deposit-payment-popup">
            <PaymentDialog
                isVisible={props.isVisible}
                setIsVisible={props.setIsVisible}
                reloadData={() => {}}
                stripe={stripe}
                elements={elements}
                priceList={mapPriceList()}
                originalServicePrice={originalServicePrice}
                servicePrice={originalServicePrice}
                totalProductPrice={totalPrice}
                apliedCredits={appliedCredits}
                setAppliedCredits={setAppliedCredits}
                isParrentLoading={isDataLoading}
                isPaymentInProcess={isPaymentInProcess}
                isAuthorizationRequired={false}
                directPayment={directPayment}
                chargeRequest={payReportDeposit}
            />
        </div>
    );
};

export default ReportDepositPaymentComponent;
