import React, { useEffect, useState } from 'react';
import { MemberAccess } from '../../../models/DataRequestHub/MemberAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import './MembersAccessLockerButton.scss';

interface MembersAccessLockerButtonProps {
    membersAccessList: MemberAccess[];
    onClick(): void;
}

const MembersAccessLockerButton = (props: MembersAccessLockerButtonProps) => {
    const [doesAnyMemberHaveAccess, setDoesAnyMemberHaveAccess] = useState(
        props.membersAccessList.some((access) => access.isAccessEnabled)
    );

    useEffect(() => {
        setDoesAnyMemberHaveAccess(
            props.membersAccessList.some((access) => access.isAccessEnabled)
        );
    }, [props.membersAccessList]);

    return props.membersAccessList?.length ? (
        <div
            className="members-access-locker-button"
            onClick={() => {
                props.onClick();
            }}>
            {doesAnyMemberHaveAccess ? (
                <FontAwesomeIcon size="xl" icon={faLockOpen} />
            ) : (
                <FontAwesomeIcon size="xl" icon={faLock} />
            )}
        </div>
    ) : (
        <>{'N/A'}</>
    );
};

export default MembersAccessLockerButton;
