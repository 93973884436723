import React, { useState } from 'react';
import './QuestionnaireStagesHost.scss';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../models/DataRequestHub/DataRequestProject';
import QuestionnaireStagesList from '../QuestionnaireStagesList/QuestionnaireStagesList';
import { QuestionnaireModuleHost } from '../DataRequestDashboardPage/QuestionnaireModuleHost/QuestionnaireModuleHost';
import { useNavigate } from 'react-router-dom';
import { DesignModeSwitcher } from '../DesignModeSwitcher/DesignModeSwitcher';
import QuestionSeenStatus from '../../../models/DataRequestHub/QuestionSeenStatus';
import { EditorModeEnum } from '../../../models/DataRequestHub/ProjectEditorModeEnum';
import { EditorTypeEnum } from '../../../models/DataRequestHub/ProjectEditorEnum';

interface QuestionnaireStagesHostProps {
    projects: DataRequestProject[];
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
    preselectedQuestionnaireFormId: number;
    questionsSeenStatus: QuestionSeenStatus[];
    markQuestionsAsSeen(
        formId: number,
        questionsIds: number[],
        subQuestionsIds: number[]
    ): void;
}

const QuestionnaireStagesHost = (props: QuestionnaireStagesHostProps) => {
    const [selectedQuestionnaireId, setSelectedQuestionnaireId] =
        useState<number>(props.preselectedQuestionnaireFormId);

    const navigate = useNavigate();

    const handleStageEditIconClicked = (formId: number) => {
        const targetProject =
            props.projects.find((project) =>
                project.dataForms.some((form) => form.id === formId)
            ) ?? props.projects.find((project) => project);

        if (targetProject) {
            navigate('/project-editor', {
                state: {
                    questionnaireEditorParams: {
                        targetQuestionnaireId: formId,
                    },
                    project: targetProject,
                    shouldNavigateToProjectViewMode: true,
                    type: EditorTypeEnum.Questionnaire,
                    mode: EditorModeEnum.EditProject,
                    additionalUrlToReturn: `?tab=${
                        EditorTypeEnum.Questionnaire + 1
                    }`,
                },
            });
        }
    };

    const renderStagesList = () => (
        <QuestionnaireStagesList
            onStageNameClick={setSelectedQuestionnaireId}
            onEditIconClick={handleStageEditIconClicked}
            isEditorMode={false}
            invalidStageIds={[]}
            {...props}
        />
    );

    const isProjectActive = (formId: number) => {
        const targetProject =
            props.projects.find((project) =>
                project.dataForms.some((form) => form.id === formId)
            ) ?? props.projects.find((project) => project);

        return targetProject.state === DataRequestProjectState.Active;
    };

    const getProject = (projectId: number) => {
        return props.projects.find((x) => x.id === projectId);
    };

    const renderStageView = () => {
        const projectId = props.projects.find((f) =>
            f.dataForms.some((s) => s.id === selectedQuestionnaireId)
        ).id;
        const questionnaireForm = getQuestionnaireForm();
        const projectVariables = props.projects.find((f) =>
            f.dataForms.some((s) => s.id === selectedQuestionnaireId)
        ).variables;
        return (
            <>
                <div className="questionnaire-stages-host-header">
                    <div className="stage-navigation-breadcrumb">
                        <label
                            className="link-text"
                            onClick={() => setSelectedQuestionnaireId(null)}>
                            Questionnaire
                        </label>
                        <label className="node-separator">{' / '}</label>
                        <label className="link-text">
                            {questionnaireForm.customName}
                        </label>
                    </div>
                    {props.isAdminView &&
                        isProjectActive(questionnaireForm.id) && (
                            <DesignModeSwitcher
                                defaultValue={false}
                                onEnable={handleStageEditIconClicked}
                                formId={
                                    questionnaireForm.id
                                }></DesignModeSwitcher>
                        )}
                </div>

                <QuestionnaireModuleHost
                    key={'questionnaire-view-form'}
                    isAdminView={props.isAdminView}
                    projectId={projectId}
                    questionnaireForm={questionnaireForm}
                    updateProjectsData={props.updateProjectsData}
                    projectVariables={projectVariables}
                    markQuestionsAsSeen={props.markQuestionsAsSeen}
                    questionsSeenStatus={props.questionsSeenStatus}
                    project={getProject(projectId)}
                />
            </>
        );
    };

    const getQuestionnaireForm = () =>
        props.projects
            .flatMap((project) => project.dataForms)
            .find((form) => form.id === selectedQuestionnaireId);

    return (
        <div className="questionnaire-stages-host">
            {selectedQuestionnaireId ? renderStageView() : renderStagesList()}
        </div>
    );
};

export default QuestionnaireStagesHost;
