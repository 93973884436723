import React from 'react';
import InfoTooltip from '../../../../components/info-tooltip/info-tooltip';
import { ProjectTypeEnum } from '../../../../models/DataRequestHub/ProjectTypeEnum';
import { AssociateMemberRights } from '../../../../utils/associateMembersConstants';
import './FeeBasedPermissionSections.scss';
import { Input, Label } from '@availity/form';

interface Practice {
    practiceId: number;
    name: string;
    isSelected: boolean;
    roleId: number;
    accessRights: AssociateMemberRights;
    coverName: string;
    coverStory: string;
    cannotBePmgRepresentative: boolean;
    availableDataRequestProjects: AffiliateProjectAccess[];
}

interface AffiliateProjectAccess {
    id: number;
    practiceId: number;
    type: ProjectTypeEnum;
    isAvailable: boolean;
    alternativeName: string;
    projectStory: string;
}

interface FeeBasedPermissionSectionsProps {
    practice: Practice;
    practiceArrayIndex: number;
    setData(value: any): void;
    areAllAccessRightsAllowed(roleId: number): boolean;
    disabled: boolean;
    data: any;
    isPmgGroupAvailable: boolean;
    doesUserHaveSubscription(): boolean;
    setFieldValue(
        fieldName: string,
        value: any,
        shouldValidate?: boolean
    ): void;
}

export const FeeBasedPermissionSections = (
    props: FeeBasedPermissionSectionsProps
) => {
    const valuationReportsInputFormSectionTooltip =
        'Provides read/write access to the data input form for a commissioned APVAR or CAPVAR practice valuation report in the Data Exchange tab on the practice-specific page in the My Practices area. Also provides access to the data input support, the usage of which may result in the automatic charging to your saved payment method of a fully creditable deposit for the report.';
    const valuationReportsProcurementSectionTooltip =
        'Enables the ability to commission and/or purchase an APVAR or CAPVAR practice Valuation Report.';
    const valuationReportsViewingSectionTooltip =
        'Provides access to the Valuation Analyses tab on the practice-specific page in the My Practices area.';

    const kpiBenchmarkingInputFormsTooltip =
        'Provides read/write access to the data input form for a commissioned KPI Benchmarking report in the Data Exchange tab on the practice-specific page in the My Practices area.';

    const accountingStandardizationInputFormsTooltip =
        'Provides upload access to the data input form for a commissioned Accounting Standardization service in the Data Exchange tab on the practice-specific page in the My Practices area.';

    const practiceSalesInputFormsTooltip =
        'Provides viewing & upload access to the upload folders and any input forms for a practice sale in the Data Exchange tab on the practice-specific page in the My Practices area.<br/>These access rights can be further tailored in your Data Exchange tab on the practice-specific page in the My Practices area. If you check this box, then we will provide you with the opportunity to create a project name and share with us any "cover story" you wish to maintain with any affiliated members provided access rights herein.';

    const getMnAProjectIndex = () => {
        const targetProject = props.practice.availableDataRequestProjects.find(
            (project) =>
                project.type === ProjectTypeEnum.MnaTransaction &&
                project.isAvailable
        );
        return props.practice.availableDataRequestProjects.indexOf(
            targetProject
        );
    };

    const valuationReportsPermissions = () => {
        return (
            <>
                <div className="section-sub-header">Valuation Reports</div>
                {getValuationReportsInputFormAccessSection()}
                {props.areAllAccessRightsAllowed(props.practice.roleId) &&
                    getValuationReportsProcurementAccessSection()}
                {props.areAllAccessRightsAllowed(props.practice.roleId) &&
                    getValuationReportsViewingAccessSection()}
            </>
        );
    };

    const kpiBenchmarkingPermissions = () => {
        const isKpiBenchmarkingProjectAvailable =
            props.practice.availableDataRequestProjects?.some(
                (projectStatus) =>
                    projectStatus.isAvailable &&
                    projectStatus.type === ProjectTypeEnum.KpiBenchmarkingReport
            ) &&
            props.isPmgGroupAvailable &&
            props.practice.accessRights.pmgRepresentativeAccessRights;

        return isKpiBenchmarkingProjectAvailable ? (
            <>
                <div className="section-sub-header">
                    KPI Benchmarking Reports
                </div>
                {getKpiBenchmarkingInputFormAccessSection()}
            </>
        ) : (
            <></>
        );
    };

    const accountingStandardizationPermissions = () => {
        const isAccountingStandardizationProjectAvailable =
            props.practice.availableDataRequestProjects?.some(
                (projectStatus) =>
                    projectStatus.isAvailable &&
                    projectStatus.type ===
                        ProjectTypeEnum.AccountingStandardization
            );

        return isAccountingStandardizationProjectAvailable ? (
            <>
                <div className="section-sub-header">
                    Accounting Standardization
                </div>
                {accountingStandardizationInputFormAccessSection()}
            </>
        ) : (
            <></>
        );
    };

    const practiceSalesPermissions = () => {
        const isPracticeSalesProjectAvailable =
            props.practice.availableDataRequestProjects?.some(
                (projectStatus) =>
                    projectStatus.isAvailable &&
                    projectStatus.type === ProjectTypeEnum.MnaTransaction
            );

        const projectIndex = getMnAProjectIndex();

        return isPracticeSalesProjectAvailable && projectIndex >= 0 ? (
            <>
                <div className="section-sub-header">Practice Sale</div>
                {practiceSalesInputFormAccessSection()}
                {props.practice.accessRights.practiceSalesInputForms ? (
                    <div className="practice-sale-cover">
                        <div className="cover-name">
                            <Label>{'Project Name'}</Label>
                            <Input
                                disabled={props.disabled}
                                name={`practices[${props.practiceArrayIndex}].availableDataRequestProjects[${projectIndex}].alternativeName`}
                                type="text"
                                value={
                                    props.practice.availableDataRequestProjects[
                                        projectIndex
                                    ].alternativeName
                                }
                                onChange={(event) => {
                                    const value = event.target.value ?? '';
                                    props.setFieldValue(
                                        `practices[${props.practiceArrayIndex}].availableDataRequestProjects[${projectIndex}].alternativeName`,
                                        value
                                    );

                                    props.practice.availableDataRequestProjects[
                                        projectIndex
                                    ].alternativeName = value;
                                    props.setData({
                                        ...props.data,
                                    });
                                }}
                                required
                            />
                        </div>

                        <div>
                            <Label>{'Project "Cover Story", if any'}</Label>
                            <Input
                                disabled={props.disabled}
                                name={`practices[${props.practiceArrayIndex}].availableDataRequestProjects[${projectIndex}].projectStory`}
                                type="textarea"
                                value={
                                    props.practice.availableDataRequestProjects[
                                        projectIndex
                                    ].projectStory
                                }
                                onChange={(event) => {
                                    const value = event.target.value ?? '';
                                    props.setFieldValue(
                                        `practices[${props.practiceArrayIndex}].availableDataRequestProjects[${projectIndex}].projectStory`,
                                        value
                                    );

                                    props.practice.availableDataRequestProjects[
                                        projectIndex
                                    ].projectStory = value;
                                    props.setData({
                                        ...props.data,
                                    });
                                }}
                                required
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </>
        ) : (
            <></>
        );
    };

    const handleAccessRigtsChnage = (
        accessRightProperty: keyof AssociateMemberRights
    ) => {
        if (!props.disabled) {
            const newValue = !props.practice.accessRights[accessRightProperty];
            props.practice.accessRights[accessRightProperty] = newValue;

            props.setFieldValue(
                `practices[${props.practiceArrayIndex}].accessRights.${accessRightProperty}`,
                newValue
            );

            props.setData({
                ...props.data,
            });
        }
    };

    const accessRightCheckbox = (
        header: string,
        accessRightPropertName: string,
        tooltipId: string,
        tooltipText: string,
        disabled: boolean
    ) => {
        const accessRightProperty =
            accessRightPropertName as keyof AssociateMemberRights;
        const isChecked = props.practice.accessRights[accessRightProperty];

        return (
            <div className="section-body">
                <div
                    className="access-right-label"
                    onClick={() =>
                        handleAccessRigtsChnage(accessRightProperty)
                    }>
                    {header}
                </div>
                <label>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        disabled={disabled}
                        onChange={() => {
                            handleAccessRigtsChnage(accessRightProperty);
                        }}
                    />
                    <span className="checkmark"></span>
                    <InfoTooltip
                        text={tooltipText}
                        idText={`practice-${props.practice.practiceId}-${tooltipId}`}
                    />
                </label>
            </div>
        );
    };

    const getValuationReportsInputFormAccessSection = () =>
        accessRightCheckbox(
            'Input Form Access',
            'valuationReportInputForms',
            'valuation-reports-input-forms-tooltip',
            valuationReportsInputFormSectionTooltip,
            props.disabled
        );

    const getKpiBenchmarkingInputFormAccessSection = () =>
        accessRightCheckbox(
            'Input Form Access',
            'kpiBenchmarkingInputForms',
            'kpi-benchmarking-input-forms-tooltip',
            kpiBenchmarkingInputFormsTooltip,
            props.disabled || !props.doesUserHaveSubscription()
        );

    const accountingStandardizationInputFormAccessSection = () =>
        accessRightCheckbox(
            'Input Form Access',
            'accountingStandardizationInputForms',
            'accounting-standardization-input-forms-tooltip',
            accountingStandardizationInputFormsTooltip,
            props.disabled || !props.doesUserHaveSubscription()
        );

    const practiceSalesInputFormAccessSection = () =>
        accessRightCheckbox(
            'Data & Document View & Upload',
            'practiceSalesInputForms',
            'practice-sales-input-forms-tooltip',
            practiceSalesInputFormsTooltip,
            props.disabled || !props.doesUserHaveSubscription()
        );

    const getValuationReportsProcurementAccessSection = () =>
        accessRightCheckbox(
            'Procurement',
            'valuationReportsProcurement',
            'valuation-reports-procurement',
            valuationReportsProcurementSectionTooltip,
            props.disabled || !props.doesUserHaveSubscription()
        );

    const getValuationReportsViewingAccessSection = () =>
        accessRightCheckbox(
            'Viewing',
            'valuationReportsViewing',
            'valuation-reports-viewing',
            valuationReportsViewingSectionTooltip,
            props.disabled || !props.doesUserHaveSubscription()
        );

    return (
        <div className="fee-based-permisstion-sections section-container">
            <div className="section-header">Fee-Based Services</div>
            {valuationReportsPermissions()}
            {kpiBenchmarkingPermissions()}
            {accountingStandardizationPermissions()}
            {practiceSalesPermissions()}
        </div>
    );
};

export default FeeBasedPermissionSections;
