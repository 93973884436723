import React, { useEffect, useState } from 'react';
import './DataInputSupportConfirmationPopup.scss';
import Rodal from '../Rodal/Rodal';
import { ModalBody, ModalHeader } from 'reactstrap';
import Helpers from '../../utils/helper';
import { useStateSelector } from '../../store/selectors';
import { WalletReportType } from '../../wallet/models/ReportTypeEnum';

interface DataInputSupportConfirmationPopupProps {
    isVisible: boolean;
    closeModal: () => void;
    onConfirm: () => void;
    reportType: WalletReportType;
}

const DataInputSupportConfirmationPopup = (
    props: DataInputSupportConfirmationPopupProps
) => {
    const pricing = useStateSelector((state) => state.pricing.pricing);
    const [reportDeposit, setReportDeposit] = useState('');
    const summary = useStateSelector((state) => state.userSummary.summary);

    useEffect(() => {
        let deposit = '';
        switch (props.reportType) {
            case WalletReportType.CAPVAR:
                deposit = Helpers.getCapvarDepositPrice(summary.hasPaidSubscription, pricing)
                break;
            default:
                break;
        }

        setReportDeposit(Helpers.GetCurrencyFormatWithCents(deposit));
    }, [props.reportType]);

    return (
        <div className="data-input-support-confirmation-popup">
            <Rodal
                visible={props.isVisible}
                onClose={props.closeModal}
                animation={'fade'}
                width={500}
                showMask={true}>
                <ModalHeader>Data Input Support</ModalHeader>
                <ModalBody>
                    <p>
                        Welcome back to CAPVAR support. A fully creditable
                        deposit is now required in order to receive further data
                        input support. This deposit will not increase the price
                        of the report.
                    </p>
                    <p>Deposit Amount:{` ${reportDeposit}`}</p>
                    <div className="buttons-wrapper">
                        <button
                            className="btn btn-secondary"
                            onClick={props.closeModal}>
                            Return to Input Form
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={props.onConfirm}>
                            Continue to Deposit
                        </button>
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};

export default DataInputSupportConfirmationPopup;
