import React, { useEffect, useState } from 'react';
import './CreateDataTablePopup.scss';
import Rodal from '../../../../../components/Rodal/Rodal';
import {
    Button,
    Col,
    Input,
    Label,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import { Multiselect } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import { useStateSelector } from '../../../../../store/selectors';
import Helpers from '../../../../../utils/helper';
import { TemplateDropdownOption } from '../../../../../models/DataRequestHub/TemplateDropdownOption';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import createNotification from '../../../../../utils/createNotification';
import { DataRequestProject } from '../../../../../models/DataRequestHub/DataRequestProject';
import { DataTable } from '../../../../../models/DataRequestHub/DataTable';

interface CreateDataTablePopupProps {
    project: DataRequestProject;
    onClose(): void;
    onAdd(): void;
}

const CreateDataTablePopup = (props: CreateDataTablePopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [dataTableTemplateOptions, setDataTableTemplateOptions] = useState<
        TemplateDropdownOption[]
    >([]);
    const [selectedDataTableTemplateIds, setSelectedDataTableTemplateIds] =
        useState<number[]>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTableListOpened, setIsTableListOpened] = useState(false);
    const [isTableViewListOpened, setIsTableViewListOpened] = useState(false);
    const [
        selectedDataTableViewTemplateIds,
        setSelectedDataTableViewTemplateIds,
    ] = useState<number[]>(null);
    const [dataTables, setDataTables] = useState<DataTable[]>([]);

    useEffect(() => {
        fetchTemplateOptions();
    }, []);

    const handleDataTableTemplateOptionsChange = (templateIds: number[]) => {
        setSelectedDataTableTemplateIds(templateIds);
    };

    const getMappedDataTableTemplateOptions = (
        templateTables: DataTable[]
    ): TemplateDropdownOption[] => {
        const result = templateTables
            .map((obj: DataTable) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: undefined,
            }))
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));

        return result;
    };

    const getTableMultiselectOptions = () => {
        return dataTableTemplateOptions.map((x) => {
            return { id: x.id, option: x.name };
        });
    };

    const fetchTemplateOptions = () => {
        axios
            .get('/api/dataRequestTemplateProject/dataTables')
            .then((response) => {
                if (response.status === 200) {
                    const dataTableTemplateOptions =
                        getMappedDataTableTemplateOptions(response.data);
                    setDataTables(response.data);
                    setDataTableTemplateOptions(dataTableTemplateOptions);
                }
            });
    };

    const addTableToProject = () => {
        setIsLoading(true);

        const params = {
            dataTableTemplateIds: selectedDataTableTemplateIds,
            dataTableViewTemplateIds: selectedDataTableViewTemplateIds ?? []
        };
        axios
            .post(
                `api/DataRequestProjects/${props.project.id}/dataTables`,
                params
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while creating new table',
                        'error'
                    );
                } else {
                    props.onAdd();
                    props.onClose();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getDataTableViewMultiselectOptions = () => {
        const tables = dataTables.filter((table) =>
            selectedDataTableTemplateIds?.includes(table.id)
        );
        const tableViews = tables.flatMap((x) => x.views);

        return tableViews.map((view) => {
            const table = tables.find((table) =>
                table.views.some((v) => v.id === view.id)
            );
            const name = `${table?.name} - ${view.name}`;

            return { id: view.id, option: name };
        });
    };

    const handleDataTableViewTemplateOptionsChange = (
        templateIds: number[]
    ) => {
        setSelectedDataTableViewTemplateIds(templateIds);
    };

    return (
        <Rodal
            visible={true}
            onClose={props.onClose}
            className="create-data-table-popup"
            width={400}
            animation="fade"
            closeMaskOnClick={false}
            centered>
            <ModalHeader>Create Additional Table</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={3}>
                        <Label>Template:</Label>
                    </Col>
                    <Col md={9} className="data-table-section-input">
                        <Multiselect
                            open={isTableListOpened}
                            onFocus={() => {
                                setIsTableListOpened(true);
                            }}
                            onBlur={() => {
                                setIsTableListOpened(false);
                            }}
                            filter="contains"
                            selectIcon={<ArrowDown />}
                            data={getTableMultiselectOptions()}
                            dataKey="option"
                            textField="option"
                            groupBy="type"
                            onChange={(value) => {
                                const ids = value.map((x) => {
                                    return x.id;
                                });
                                handleDataTableTemplateOptionsChange(ids);
                                if (
                                    ids.length ===
                                    getTableMultiselectOptions().length
                                ) {
                                    setIsTableListOpened(false);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Label>Data Table Views:</Label>
                    </Col>
                    <Col md={9} className="data-table-section-input">
                        <Multiselect
                            open={isTableViewListOpened}
                            onFocus={() => {
                                setIsTableViewListOpened(true);
                            }}
                            onBlur={() => {
                                setIsTableViewListOpened(false);
                            }}
                            filter="contains"
                            selectIcon={<ArrowDown />}
                            data={getDataTableViewMultiselectOptions()}
                            dataKey="option"
                            textField="option"
                            groupBy="type"
                            onChange={(value) => {
                                const ids = value.map((x) => {
                                    return x.id;
                                });
                                handleDataTableViewTemplateOptionsChange(ids);
                                if (
                                    ids.length ===
                                    getDataTableViewMultiselectOptions().length
                                ) {
                                    setIsTableViewListOpened(false);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="buttons-wrapper">
                        <Button
                            className="btn btn-cancel btn btn-secondary"
                            onClick={props.onClose}>
                            Close
                        </Button>
                        <ButtonLoader
                            onClick={addTableToProject}
                            className={'btn btn-primary'}
                            buttonText={'Create'}
                            loaderButtonText={''}
                            isLoading={isLoading}
                            disabled={
                                !selectedDataTableTemplateIds?.length
                            }></ButtonLoader>
                    </Col>
                </Row>
            </ModalBody>
        </Rodal>
    );
};

export default CreateDataTablePopup;
