import React, { useEffect, useState } from 'react';
import { WalletReportType } from '../../../../wallet/models/ReportTypeEnum';
import DataInputSupportConfirmationPopup from '../../../../components/DataInputSupportConfirmationPopup/DataInputSupportConfirmationPopup';
import ContactSupportButton from '../../../ContactSupportButton/ContactSupportButton';
import { SupportContextEnum } from '../../../../support-portal/models/SupportContext';
import ReportDepositPaymentComponent from '../../../../ReportDepositPaymentComponent/ReportDepositPaymentComponent';
import { SupportThreadStatus } from '../../../../support-portal/models/SupportThread';
import { useStateSelector } from '../../../../store/selectors';

export interface CapvarSupportRequestWrapperProps {
    practiceId: number;
}

export const CapvarSupportRequestWrapper = (
    props: CapvarSupportRequestWrapperProps
) => {
    const axios = useStateSelector((state) => state.core.axios);
    const supportThreads = useStateSelector(
        (state) => state.supportThreads.threads
    );
    let initiateRequestTimeout: NodeJS.Timeout = null;
    const [depositStatusLoading, setDepositStatusLoading] = useState(false);
    const [
        isDataInputSupportConfirmationPopupVisible,
        setIsDataInputSupportConfirmationPopupVisible,
    ] = useState(false);
    const [isDepositPayemntPopupVisible, setIsDepositPayemntPopupVisible] =
        useState(false);
    const [isUnspentDepositExists, setIsUnspentDepositExists] = useState(false);
    const [
        initiateInputSupportThreadCallBack,
        setInitiateInputSupportThreadCallBack,
    ] = useState<() => void>(() => () => {});
    const [lastInitiatedThread, setLastInitiatedThread] = useState(null);
    const [isFreeRequestAvailable, setIsFreeRequestAvailable] = useState(false);
    const [lastSuccessfulReportPayment, setLastSuccessfulReportPayment] =
        useState(null);

    useEffect(() => {
        const lastThread = supportThreads.find(
            (f) => f.supportContext === SupportContextEnum.Capvar
        );
        setLastInitiatedThread(lastThread);

        if (lastThread) {
            const isFreeRequestRefreshed =
                lastThread?.dateInitiated < lastSuccessfulReportPayment?.date;
            setIsFreeRequestAvailable(isFreeRequestRefreshed);
        } else {
            setIsFreeRequestAvailable(true);
        }
    }, [supportThreads, lastSuccessfulReportPayment]);

    useEffect(() => {
        fetchLastSuccessfulReportPayment();
        fetchIsReportDepositPaid();
    }, []);

    const fetchLastSuccessfulReportPayment = () => {
        axios
            .get(
                `api/wallet/transactions/lastSuccessfulReportPayment?WalletReportType=${WalletReportType.CAPVAR}&practiceId=${props.practiceId}`
            )
            .then((response) => {
                if (response.status === 200) {
                    setLastSuccessfulReportPayment(response.data);
                }
            });
    };

    const fetchIsReportDepositPaid = () => {
        setDepositStatusLoading(true);
        axios
            .get(
                `api/wallet/transactions/unspentReportDeposit?WalletReportType=${WalletReportType.CAPVAR}&practiceId=${props.practiceId}`
            )
            .then((response: any) => {
                if (response.status === 200) {
                    setIsUnspentDepositExists(!!response.data);
                }
            })
            .finally(() => {
                setDepositStatusLoading(false);
            });
    };

    const handleSupportRequestButtonClick = (
        initiateSupportThreadCallback: () => void
    ) => {
        if (!depositStatusLoading) {
            if (
                isUnspentDepositExists ||
                !lastInitiatedThread ||
                lastInitiatedThread.status !== SupportThreadStatus.Stopped ||
                isFreeRequestAvailable
            ) {
                if (initiateRequestTimeout) {
                    clearTimeout(initiateRequestTimeout);
                }

                initiateRequestTimeout = setTimeout(
                    () => initiateSupportThreadCallback(),
                    1000
                );
            } else {
                setIsDataInputSupportConfirmationPopupVisible(true);
                setInitiateInputSupportThreadCallBack(
                    () => initiateSupportThreadCallback
                );
            }
        }
    };

    return (
        <div className="capvar-support-request-wrapper">
            <div className="buttons-wrapper">
                <ContactSupportButton
                    practiceId={props.practiceId}
                    supportContext={SupportContextEnum.Capvar}
                    buttonType="reference"
                    buttonText="Data Input Support"
                    tooltipTargetId="capvar-header-support-button"
                    tooltipText="Limited complimentary data input support is provided for a CAPVAR report."
                    onButtonClick={handleSupportRequestButtonClick}
                />
            </div>

            {isDataInputSupportConfirmationPopupVisible ? (
                <DataInputSupportConfirmationPopup
                    isVisible={isDataInputSupportConfirmationPopupVisible}
                    closeModal={() =>
                        setIsDataInputSupportConfirmationPopupVisible(false)
                    }
                    onConfirm={() => {
                        setIsDataInputSupportConfirmationPopupVisible(false);
                        setIsDepositPayemntPopupVisible(true);
                    }}
                    reportType={
                        WalletReportType.CAPVAR
                    }></DataInputSupportConfirmationPopup>
            ) : (
                []
            )}
            {isDepositPayemntPopupVisible ? (
                <ReportDepositPaymentComponent
                    isVisible={isDepositPayemntPopupVisible}
                    setIsVisible={setIsDepositPayemntPopupVisible}
                    paymentSuccess={() => {
                        initiateInputSupportThreadCallBack();
                        fetchIsReportDepositPaid();
                        setIsDepositPayemntPopupVisible(false);
                    }}
                    type={WalletReportType.CAPVAR}
                />
            ) : (
                []
            )}
        </div>
    );
};
