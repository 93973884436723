import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Multiselect } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import ReactSwitch from 'react-switch';

interface CreateDataTableFormProps {
    isDataTablesVisible: boolean;
    setIsDataTablesVisible: React.Dispatch<React.SetStateAction<boolean>>;
    getTableMultiselectOptions: () => {
        id: number;
        option: string;
    }[];
    selectedDataTableTemplateIds: number[];
    handleDataTableTemplateOptionsChange: (templateIds: number[]) => void;
    getDataTableViewMultiselectOptions: () => {
        id: number;
        option: string;
    }[];
    selectedDataTableViewTemplateIds: number[];
    handleDataTableViewTemplateOptionsChange: (templateIds: number[]) => void;
    getTableViewsByQuestionnaireId: () => number[];
    getTablesByQuestionnaireId: () => number[];
    setSelectedDataTableViewTemplateIds: React.Dispatch<
        React.SetStateAction<number[]>
    >;
    setSelectedDataTableTemplateIds: React.Dispatch<
        React.SetStateAction<number[]>
    >;
}

export const CreateDataTableForm = (props: CreateDataTableFormProps) => {
    const [isTableListOpened, setIsTableListOpened] = useState(false);
    const [isTableViewListOpened, setIsTableViewListOpened] = useState(false);

    const renderTagList = ({ value, clearTagIcon }: any) => {
        const notRemovableTableIds = props.getTablesByQuestionnaireId();

        return (
            <div className="rw-multiselect-taglist">
                {value.map((item: any) => {
                    const isRemovable = !notRemovableTableIds.some(
                        (id) => id === item.id
                    );

                    return (
                        <div className="rw-multiselect-tag" key={item.id}>
                            <span className="rw-multiselect-tag-label">
                                {item.option}
                            </span>
                            {isRemovable && (
                                <button
                                    className="rw-multiselect-tag-btn"
                                    onMouseDown={() => {
                                        props.setSelectedDataTableTemplateIds(
                                            (prevState) => {
                                                return prevState.filter(
                                                    (x) => x !== item.id
                                                );
                                            }
                                        );
                                    }}
                                    aria-label="Remove item">
                                    {clearTagIcon}
                                </button>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderTableViewsTagList = ({ value, clearTagIcon }: any) => {
        const notRemovableTableViewIds = props.getTableViewsByQuestionnaireId();

        return (
            <div className="rw-multiselect-taglist">
                {value.map((item: any) => {
                    const isRemovable = !notRemovableTableViewIds.some(
                        (id) => id === item.id
                    );

                    return (
                        <div className="rw-multiselect-tag" key={item.id}>
                            <span className="rw-multiselect-tag-label">
                                {item.option}
                            </span>
                            {isRemovable && (
                                <button
                                    className="rw-multiselect-tag-btn"
                                    onMouseDown={() => {
                                        props.setSelectedDataTableViewTemplateIds(
                                            (prevState) => {
                                                return prevState.filter(
                                                    (x) => x !== item.id
                                                );
                                            }
                                        );
                                    }}
                                    aria-label="Remove item">
                                    {clearTagIcon}
                                </button>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="multiselect">
            <Row>
                <Col md={6}>
                    <div className="field-switcher">
                        <ReactSwitch
                            offColor="#d92550"
                            checked={props.isDataTablesVisible}
                            onChange={(event) => {
                                props.setIsDataTablesVisible(event);
                            }}
                            height={22}></ReactSwitch>
                        <div className="field-label">Data Tables</div>
                    </div>
                </Col>
                <Col md={6} className="data-table-section-input">
                    <Multiselect
                        open={isTableListOpened}
                        onFocus={() => {
                            setIsTableListOpened(true);
                        }}
                        onBlur={() => {
                            setIsTableListOpened(false);
                        }}
                        filter="contains"
                        disabled={!props.isDataTablesVisible}
                        selectIcon={<ArrowDown />}
                        data={props.getTableMultiselectOptions()}
                        value={props
                            .getTableMultiselectOptions()
                            .filter((x) =>
                                props.selectedDataTableTemplateIds?.includes(
                                    x.id
                                )
                            )}
                        tagListComponent={renderTagList}
                        dataKey="option"
                        textField="option"
                        groupBy="type"
                        onChange={(value) => {
                            const ids = value.map((x) => {
                                return x.id;
                            });
                            props.handleDataTableTemplateOptionsChange(ids);
                            if (
                                ids.length ===
                                props.getTableMultiselectOptions().length
                            ) {
                                setIsTableListOpened(false);
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="field-switcher ml-56">
                        <div className="field-label">Data Table Views</div>
                    </div>
                </Col>
                <Col md={6} className="data-table-section-input">
                    <Multiselect
                        open={isTableViewListOpened}
                        onFocus={() => {
                            setIsTableViewListOpened(true);
                        }}
                        tagListComponent={renderTableViewsTagList}
                        onBlur={() => {
                            setIsTableViewListOpened(false);
                        }}
                        filter="contains"
                        disabled={!props.isDataTablesVisible}
                        selectIcon={<ArrowDown />}
                        value={props
                            .getDataTableViewMultiselectOptions()
                            .filter((x) =>
                                props.selectedDataTableViewTemplateIds?.includes(
                                    x.id
                                )
                            )}
                        data={props.getDataTableViewMultiselectOptions()}
                        dataKey="option"
                        textField="option"
                        groupBy="type"
                        onChange={(value) => {
                            const ids = value.map((x) => {
                                return x.id;
                            });
                            props.handleDataTableViewTemplateOptionsChange(ids);
                            if (
                                ids.length ===
                                props.getDataTableViewMultiselectOptions()
                                    .length
                            ) {
                                setIsTableViewListOpened(false);
                            }
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};
