export const ASSOCIATE_MEMBER_ROLES = [
    { id: 1, name: 'Fellow Practice Owner' },
    { id: 2, name: 'Practice Manager' },
    { id: 3, name: 'Company Bookkeeper' },
    { id: 4, name: 'Outside Accountant' },
];

export const ASSOCIATE_MEMBER_ROLES_ENUM = {
    FellowPracticeOwner: 1,
    PracticeManager: 2,
    CompanyBookkeeper: 3,
    OutsideAccountant: 4,
};

export const ASSOCIATE_MEMBER_STATUS = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Approved' },
];

export const ASSOCIATE_MEMBER_RIGHTS = [
    { id: 1, name: 'IPOC' },
    { id: 2, name: 'Knowledge Library' },
    { id: 3, name: 'Valuation Reports Input Form' },
    { id: 4, name: 'Valuation Reports Procurement' },
    { id: 5, name: 'Valuation Reports Viewing' },
    { id: 6, name: 'Referral Partner Extension' },
    { id: 7, name: 'PmgRepresentativeAccessRights' },
    { id: 8, name: 'KPI Benchmarking Input Forms' },
    { id: 9, name: 'KPI Benchmarking Procurement' },
    { id: 10, name: 'KPI Benchmarking Viewing' },
    { id: 11, name: 'Accounting Standardization Input Forms' },
    { id: 12, name: 'Accounting Standardization Procurement' },
    { id: 13, name: 'Practice Sale InputForms' },
    { id: 14, name: 'Practice Sale Procurement' },
    { id: 15, name: 'Practice Sale Viewing' },
];

export const ASSOCIATE_MEMBER_RIGHTS_ENUM = {
    POC: 1,
    KnowledgeBase: 2,
    ValuationReportInputForms: 3,
    ValuationReportsProcurement: 4,
    ValuationReportsViewing: 5,
    ReferralPartnerExtension: 6,
    PmgRepresentativeAccessRights: 7,
    KpiBenchmarkingInputForms: 8,
    KpiBenchmarkingProcurement: 9,
    KpiBenchmarkingViewing: 10,
    AccountingStandardizationInputForms: 11,
    AccountingStandardizationProcurement: 12,
    PracticeSalesInputForms: 13,
    PracticeSalesProcurement: 14,
    PracticeSalesViewing: 15,
};

export interface AssociateMemberRights {
    practiceOwnerCommunity: boolean;
    knowledgeBase: boolean;
    valuationReportInputForms: boolean;
    valuationReportsProcurement: boolean;
    valuationReportsViewing: boolean;
    referralPartnerExtension: boolean;
    pmgRepresentativeAccessRights: boolean;
    kpiBenchmarkingInputForms: boolean;
    kpiBenchmarkingProcurement: boolean;
    kpiBenchmarkingViewing: boolean;
    accountingStandardizationInputForms: boolean;
    accountingStandardizationProcurement: boolean;
    practiceSalesInputForms: boolean;
    practiceSalesProcurement: boolean;
    practiceSalesViewing: boolean;
}

export const getDefaultAssosiateMemberRights = (): AssociateMemberRights => {
    return {
        practiceOwnerCommunity: false,
        knowledgeBase: false,
        valuationReportInputForms: false,
        valuationReportsProcurement: false,
        valuationReportsViewing: false,
        referralPartnerExtension: false,
        pmgRepresentativeAccessRights: false,
        kpiBenchmarkingInputForms: false,
        kpiBenchmarkingProcurement: false,
        kpiBenchmarkingViewing: false,
        accountingStandardizationInputForms: false,
        accountingStandardizationProcurement: false,
        practiceSalesInputForms: false,
        practiceSalesProcurement: false,
        practiceSalesViewing: false,
    };
};

export const getMappedAssosiateMemberRights = (
    numericArrayToMap: number[]
): AssociateMemberRights => {
    if (!numericArrayToMap) {
        return getDefaultAssosiateMemberRights();
    }

    const result: AssociateMemberRights = {
        practiceOwnerCommunity: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.POC
        ),
        knowledgeBase: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
        ),
        valuationReportInputForms: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportInputForms
        ),
        valuationReportsProcurement: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportsProcurement
        ),
        valuationReportsViewing: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportsViewing
        ),
        referralPartnerExtension: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.ReferralPartnerExtension
        ),
        pmgRepresentativeAccessRights: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.PmgRepresentativeAccessRights
        ),
        kpiBenchmarkingInputForms: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.KpiBenchmarkingInputForms
        ),
        kpiBenchmarkingProcurement: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.KpiBenchmarkingProcurement
        ),
        kpiBenchmarkingViewing: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.KpiBenchmarkingViewing
        ),
        accountingStandardizationInputForms: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.AccountingStandardizationInputForms
        ),
        accountingStandardizationProcurement: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.AccountingStandardizationProcurement
        ),
        practiceSalesInputForms: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.PracticeSalesInputForms
        ),
        practiceSalesProcurement: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.PracticeSalesProcurement
        ),
        practiceSalesViewing: numericArrayToMap.includes(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.PracticeSalesViewing
        ),
    };
    return result;
};

export const acceptedAccessRightsToNumbers = (
    accessRights: AssociateMemberRights
) => {
    const result = [];

    if (accessRights.practiceOwnerCommunity) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.POC);
    }

    if (accessRights.knowledgeBase) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase);
    }

    if (accessRights.valuationReportInputForms) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportInputForms);
    }

    if (accessRights.valuationReportsProcurement) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportsProcurement);
    }

    if (accessRights.valuationReportsViewing) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.ValuationReportsViewing);
    }

    if (accessRights.referralPartnerExtension) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.ReferralPartnerExtension);
    }

    if (accessRights.pmgRepresentativeAccessRights) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.PmgRepresentativeAccessRights);
    }

    if (accessRights.kpiBenchmarkingInputForms) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.KpiBenchmarkingInputForms);
    }

    if (accessRights.kpiBenchmarkingProcurement) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.KpiBenchmarkingProcurement);
    }

    if (accessRights.kpiBenchmarkingViewing) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.KpiBenchmarkingViewing);
    }

    if (accessRights.accountingStandardizationInputForms) {
        result.push(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.AccountingStandardizationInputForms
        );
    }

    if (accessRights.accountingStandardizationProcurement) {
        result.push(
            ASSOCIATE_MEMBER_RIGHTS_ENUM.AccountingStandardizationProcurement
        );
    }

    if (accessRights.practiceSalesInputForms) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.PracticeSalesInputForms);
    }

    if (accessRights.practiceSalesProcurement) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.PracticeSalesProcurement);
    }

    if (accessRights.practiceSalesViewing) {
        result.push(ASSOCIATE_MEMBER_RIGHTS_ENUM.PracticeSalesViewing);
    }

    return result;
};
