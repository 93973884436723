import React from 'react';
import './DataTableFormPopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import { ModalBody, ModalHeader } from 'reactstrap';
import { DataTableForm } from '../DataTableForm/DataTableForm';
import { DataRequestProject } from '../../../models/DataRequestHub/DataRequestProject';
import { DataTable } from '../../../models/DataRequestHub/DataTable';
import { useStateSelector } from '../../../store/selectors';

interface DataTableFormPopupProps {
    header: string;
    triggerNext?: () => void;
    triggerBack?: () => void;
    selectedTableViewId: number;
    selectedDataTable: DataTable;
    project: DataRequestProject;
    isAdminView: boolean;
    isReadonlyMode: boolean;
    updateProjectsData?: (isInitialFetch?: boolean) => void;
}

export const DataTableFormPopup = (props: DataTableFormPopupProps) => {
    const axios = useStateSelector((s) => s.core.axios);

    const handleBackStep = () => {
        if (props.triggerBack) {
            props.triggerBack();
        }
    };

    const markCellsAsSeen = (
        projectId: number,
        tableId: number,
        viewId: number
    ) => {
        if (tableId && viewId) {
            axios.put(
                `/api/dataRequestProjects/${projectId}/dataTables/${tableId}/DataTableViews/${viewId}/seenFlag`
            );
        }
    };

    return (
        <Rodal
            visible={true}
            onClose={handleBackStep}
            className="table-form-popup"
            width={500}
            animation="fade"
            closeMaskOnClick={false}
            centered>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalBody>
                <DataTableForm
                    isAdminView={props.isAdminView}
                    projectId={props.project.id}
                    variables={props.project.variables}
                    dataTable={props.selectedDataTable}
                    selectedTableViewId={props.selectedTableViewId}
                    updateProjectsData={
                        props.updateProjectsData
                            ? props.updateProjectsData
                            : () => {}
                    }
                    markCellsAsSeen={
                        props.isReadonlyMode ? () => {} : markCellsAsSeen
                    }
                    isReadonlyMode={props.isReadonlyMode}
                />
            </ModalBody>
        </Rodal>
    );
};
