import React from 'react';
import { AccessObjectType } from './AccessObjectType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

export interface MemberAccess {
    id: number;
    memberId: number;
    accessObjectType: AccessObjectType;
    isAccessEnabled: boolean;
    memberName: string;
    memberRoleName: string;
}

export const dataRequestItemAccessRightColumnTooltipText = (
    <div>
        {`A `}
        <FontAwesomeIcon icon={faLock} />
        {` indicates no affiliated members have access rights to this folder. An `}
        <FontAwesomeIcon icon={faLockOpen} />
        {` indicates that at least one affiliated member has been granted access rights to this folder`}
    </div>
);

export const dataRequestItemAccessRightPopupHeaderTooltipText = `Click to lock or unlock access to this folder for any affiliated members to whom you have already granted general access via your "My Affiliated Members" page.`;
