export enum WalletReportType {
    FREEMIUM = 1,
    CAPVAR = 2,
    CONSULTINGSERVICES = 3,
    APVAR = 4,
    ALL = 5,
    KPI = 6,
    PVC = 7,
    InitialStandardization12 = 8,
    InitialStandardization24 = 9,
    InitialStandardization36 = 10,
    InitialStandardizationYtd = 11,
    MaintenanceStandardizationMonthly = 12,
    MaintenanceStandardizationQuarterly = 13,
    MaintenanceStandardizationSemiAnnually = 14,
    MaintenanceStandardizationAnnually = 15,
}
