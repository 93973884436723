import React, { useState } from 'react';
import {
    dataRequestItemAccessRightPopupHeaderTooltipText,
    MemberAccess,
} from '../../../models/DataRequestHub/MemberAccess';
import './MemberAccessPopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import ReactDOMServer from 'react-dom/server';

interface MemberAccessPopupProps {
    memberAccessList: MemberAccess[];
    updateUrl: string;
    closePopup(): void;
    onSuccessfulUpdate(): void;
}

const MemberAccessPopup = (props: MemberAccessPopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(false);
    const [memberAccessList, setMemberAccessList] = useState(
        props.memberAccessList ?? []
    );

    const updatePermission = (member: MemberAccess) => {
        const targetMemberIndex = memberAccessList.indexOf(member);
        const updatedMemberAccess: MemberAccess = {
            ...member,
            isAccessEnabled: !member.isAccessEnabled,
        };

        const updatedAccessList = memberAccessList.map((item, index) =>
            index === targetMemberIndex ? updatedMemberAccess : item
        );

        setMemberAccessList([...updatedAccessList]);
    };

    const saveMemberAccess = () => {
        setIsLoading(true);
        axios
            .put(props.updateUrl, memberAccessList)
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while updating members permissions',
                        'error'
                    );
                } else {
                    props.onSuccessfulUpdate();
                    props.closePopup();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const renderMemberRow = (member: MemberAccess) => {
        return (
            <Row>
                <div
                    onClick={() => updatePermission(member)}
                    className="member-row">
                    {member.isAccessEnabled ? (
                        <FontAwesomeIcon
                            icon={faLockOpen}
                            className="locker-icon"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faLock}
                            className="locker-icon"
                        />
                    )}
                    <div>{`${member?.memberName ?? 'N/A'}, ${
                        member?.memberRoleName ?? 'N/A'
                    }`}</div>
                </div>
            </Row>
        );
    };

    const renderNoAffiliatedMemberAlert = () => (
        <>{'Affiliated Members not found'}</>
    );

    return (
        <div className="member-access-popup">
            <Rodal
                visible
                onClose={() => props.closePopup()}
                width={500}
                animation={'fade'}
                showMask={false}>
                <ModalHeader>
                    <div>
                        {'Affiliated Members Permissions'}
                        <InfoTooltip
                            idText="affiliated-members-permissions-popup"
                            text={
                                dataRequestItemAccessRightPopupHeaderTooltipText
                            }
                        />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Col className="member-rows-wrapper">
                        {memberAccessList?.length
                            ? memberAccessList.map((member) => {
                                  return renderMemberRow(member);
                              })
                            : renderNoAffiliatedMemberAlert()}
                    </Col>
                    <div className="buttons-wrapper">
                        <Button
                            type="button"
                            className="btn btn-cancel"
                            disabled={isLoading}
                            onClick={() => {
                                props.closePopup();
                            }}>
                            Cancel & Close
                        </Button>
                        <ButtonLoader
                            buttonText={`Save & Close`}
                            loaderButtonText={''}
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={() => saveMemberAccess()}
                            className={
                                'btn btn-primary loader-submit'
                            }></ButtonLoader>
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};

export default MemberAccessPopup;
