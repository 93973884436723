import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './EditableTable.scss';
import {
    DataGrid,
    GridColDef,
    GridRowModesModel,
    GridRowsProp,
    GridRowModel,
    GridValidRowModel,
    DataGridProps,
    GridCellParams,
} from '@mui/x-data-grid';
import EditToolbar from './EditToolbar';
import RowActionColumn from './RowActionColumn';
import { SxProps } from '@mui/material';
import RowVisibilitySwitcherColumn from './CustomColumns/RowVisibilitySwitcherColumn';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { EditorTypeEnum } from '../../models/DataRequestHub/ProjectEditorEnum';

interface EditableTableProps {
    columns: GridColDef[];
    rows: GridRowsProp;
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    isLoading?: boolean;
    fieldToFocus?: string;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    editorType: EditorTypeEnum;
    validateAllRows?(): void;
    onColumnResize?(params: { field: string; width: number }): void;
    disableActionColumn?: boolean;
    disableVisibilityColumn?: boolean;
    customActionColumn?: GridColDef;
    onCellClick?(params: GridCellParams): void;
}

const EditableTable = forwardRef((props: EditableTableProps, ref) => {
    const defaultRowPixelHeight = 34;
    const toolbarRef = useRef(null);
    useImperativeHandle(ref, () => ({
        addRow() {
            const newRowId = toolbarRef?.current.addRow();
            props.gridApiRef.current.forceUpdate();
            props.gridApiRef.current.setCellFocus(
                newRowId,
                props.fieldToFocus ?? ''
            );
        },
    }));

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const getRowId = (row: GridValidRowModel) => {
        if (props.editorType === EditorTypeEnum.Questionnaire) {
            return row.customQuestionId;
        }
        if (props.editorType === EditorTypeEnum.DataTable) {
            return row.customColumnId;
        }

        if (props.editorType === EditorTypeEnum.DataTableView) {
            return row.customColumnId;
        }

        if (props.editorType === EditorTypeEnum.DataTableData) {
            return row.customRowId;
        }

        return row.gridId ?? row.id;
    };
    const actionColumn = RowActionColumn({
        setRows: props.setRows,
        gridApiRef: props.gridApiRef,
        getRowId: getRowId,
    });

    const visibleColumn = RowVisibilitySwitcherColumn({
        setRows: props.setRows,
        gridApiRef: props.gridApiRef,
        getRowId: getRowId,
        isDataTableView: props.editorType === EditorTypeEnum.DataTableView,
    });

    const columns = (): GridColDef[] => {
        let result = props.disableVisibilityColumn ? [] : [visibleColumn];

        result = result.concat(props.columns);

        if (!props.disableActionColumn) {
            if (props.customActionColumn) {
                result = result.concat([props.customActionColumn]);
            } else {
                result = result.concat([actionColumn]);
            }
        }
        return result;
    };

    const processRowUpdate: DataGridProps['processRowUpdate'] = (
        newRow: GridRowModel
    ) => {
        const updatedRow = { ...newRow, isNew: false };
        var updatedRows = props.rows.map((row) => {
            const rowId = getRowId(row);
            const newRowId = getRowId(newRow);
            if (rowId === newRowId) {
                return updatedRow;
            }
            return row;
        });

        props.setRows(() => [...updatedRows]);
        props.gridApiRef.current.forceUpdate();
        return newRow;
    };

    const sxProps: SxProps = {
        overflow: 'auto',
        maxHeight: '700px',
        border: 'none',
        fontFamily: 'SegoeUI-Semibold',
        fontSize: '13px',
        '& .MuiDataGrid-main': {
            border: '1px solid #e9ecef',
        },
        '& .MuiDataGrid-scrollbar': {
            '--DataGrid-scrollbarSize': '6px !important',
        },
        '& .MuiDataGrid-filler': {
            '--DataGrid-scrollbarSize': '6px !important',
            height: '6px !important',
        },
        '& .MuiDataGrid-columnHeaders': {
            borderRadius: '1px',
            backgroundColor: '#dce0e4 !important',
            '--DataGrid-containerBackground': '#dce0e4 !important',
            color: '#525a64',
        },
        '& .MuiDataGrid-columnHeader': {
            border: '1px solid #e9ecef',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeader:focus':
            {
                outline: 'none',
            },
        '& .MuiDataGrid-virtualScrollerContent': {
            color: '#888f96',
        },
        '& .MuiInputBase-root': {
            color: '#888f96',
            fontFamily: 'SegoeUI-Semibold',
            fontSize: '13px',
        },
        '& .MuiDataGrid-overlayWrapper, .MuiDataGrid-overlayWrapperInner': {
            height: '50px !important',
        },
        '& .MuiDataGrid-cell .MuiDataGrid-cellContent': {
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            WebkitLineClamp: 3,
        },
    };

    return (
        <div className="editable-table">
            <DataGrid
                onStateChange={() => {
                    if (props.validateAllRows) {
                        props.validateAllRows();
                    }
                }}
                apiRef={props.gridApiRef}
                sx={sxProps}
                onColumnResize={(params) => {
                    if (props.onColumnResize) {
                        props.onColumnResize({
                            width: params.width,
                            field: params.colDef.field,
                        });
                    }
                }}
                columnHeaderHeight={defaultRowPixelHeight}
                showCellVerticalBorder={true}
                disableColumnFilter
                disableColumnMenu
                getRowId={getRowId}
                rows={props.rows}
                columns={columns()}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as any,
                }}
                slotProps={{
                    toolbar: {
                        rows: props.rows,
                        setRows: props.setRows,
                        setRowModesModel,
                        isLoading: props.isLoading,
                        focusField: props.fieldToFocus,
                        ref: toolbarRef,
                        editorType: props.editorType,
                    },
                    baseSelect: {
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        minHeight: '36px',
                                    },
                                },
                            },
                        },
                    },
                }}
                hideFooterSelectedRowCount
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            allowedFileFormat: false,
                            maxTextSize: false,
                        },
                    },
                }}
                getRowHeight={() => {
                    return 'auto';
                }}
                onCellClick={(params) => {
                    if (props.onCellClick) {
                        props?.onCellClick(params);
                    }
                }}
            />
        </div>
    );
});

export default EditableTable;
