import React, { useEffect, useState } from 'react';
import './TableSettingsPopup.scss';
import Rodal from '../../../../../components/Rodal/Rodal';
import { Button, Col, Input, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DropdownList } from 'react-widgets/cjs';
import { ArrowDown } from '../../../../../components/arrow-down/arrow-down';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import ButtonLoader from '../../../../../components/Layout/Buttons/ButtonLoader';
import { DataForm } from '../../../../../models/DataRequestHub/DataForm';
import { DataFormType } from '../../../../../models/DataRequestHub/DataFormTypeEnum';
import ReactSwitch from 'react-switch';
import Helpers from '../../../../../utils/helper';
import { DataTable } from '../../../../../models/DataRequestHub/DataTable';
import { TemplateDropdownOption } from '../../../../../models/DataRequestHub/TemplateDropdownOption';
import { CreateDataTableForm } from '../CreateDataTableForm/CreateDataTableForm';

export interface TableSettingsPopupProps {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit(): void;
    dataForms: DataForm[];
    dataTables: DataTable[];
    projectId: number;
}

interface QuestionnaireStageVisibilityParams {
    id: number;
    isVisible: boolean;
    formName: string;
}

interface DataTableAndViewVisibilityParams {
    tableId: number;
    tableViewId: number;
    isVisible: boolean;
    name: string;
}

export const TableSettingsPopup = (props: TableSettingsPopupProps) => {
    const axios = useStateSelector((state) => state.core.axios);

    const [documentTemplateOptions, setDocumentTemplateOptions] = useState([]);
    const [
        financialRequestsTemplateOptions,
        setFinancialRequestsTemplateOptions,
    ] = useState([]);
    const [questionnaireTemplateOptions, setQuestionnaireTemplateOptions] =
        useState([]);
    const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] =
        useState<number>(null);
    const [
        selectedFinancialRequestsTemplateId,
        setSelectedFinancialRequestsTemplateId,
    ] = useState<number>(null);
    const [
        selectedQuestionnaireTemplateId,
        setSelectedQuestionnaireTemplateId,
    ] = useState<number>(null);
    const [questionnaireFormName, setQuestionnaireFormName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDocumentDataFormVisible, setIsDocumentDataFormVisible] =
        useState(true);
    const [
        isFinancialRequestsDataFormVisible,
        setIsFinancialRequestsDataFormVisible,
    ] = useState(true);
    const [isQuestionnaireDataFormVisible, setIsQuestionnaireDataFormVisible] =
        useState(true);
    const [questionnaireStagesVisibility, setQuestionnaireStagesVisibility] =
        useState<QuestionnaireStageVisibilityParams[]>([]);
    const [dataTableVisibility, setDataTableVisibility] = useState<
        DataTableAndViewVisibilityParams[]
    >([]);
    const [dataTableViewVisibility, setDataTableViewVisibility] = useState<
        DataTableAndViewVisibilityParams[]
    >([]);
    const [isDataTableAvailable, setIsDataTableAvailable] = useState(false);
    const [isDataTablesVisible, setIsDataTablesVisible] = useState(true);
    const [questionnaireForms, setQuestionnaireForms] = useState<DataForm[]>(
        []
    );
    const [
        selectedDataTableViewTemplateIds,
        setSelectedDataTableViewTemplateIds,
    ] = useState<number[]>(null);
    const [dataTables, setDataTables] = useState<DataTable[]>([]);
    const [selectedDataTableTemplateIds, setSelectedDataTableTemplateIds] =
        useState<number[]>(null);
    const [dataTableTemplateOptions, setDataTableTemplateOptions] = useState<
        TemplateDropdownOption[]
    >([]);

    useEffect(() => {
        getTemplateOptions();
    }, []);

    useEffect(() => {
        const isDocumentFormVisible = props.dataForms.some(
            (x) => x.formType === DataFormType.Documents && x.isVisible
        );
        setIsDocumentDataFormVisible(isDocumentFormVisible);

        const isFinancialRequestsFormVisible = props.dataForms.some(
            (x) => x.formType === DataFormType.FinancialRequest && x.isVisible
        );
        setIsFinancialRequestsDataFormVisible(isFinancialRequestsFormVisible);

        const isQuestionnaireDataFormVisible = props.dataForms.some(
            (x) => x.formType === DataFormType.Questionnaire && x.isVisible
        );

        setIsQuestionnaireDataFormVisible(isQuestionnaireDataFormVisible);

        const stagesVisibilityParams = props.dataForms
            .filter((form) => form.formType === DataFormType.Questionnaire)
            .map(
                (form): QuestionnaireStageVisibilityParams => ({
                    id: form.id,
                    isVisible: form.isVisible,
                    formName: form.customName ?? form.originalTemplateName,
                })
            );

        setQuestionnaireStagesVisibility(stagesVisibilityParams);

        const dataTableVisibilityParams = props.dataTables.map(
            (x): DataTableAndViewVisibilityParams => ({
                tableId: x.id,
                tableViewId: 0,
                isVisible: x.isVisible,
                name: x.name ?? x.originalTemplateName,
            })
        );
        const dataTableViewVisibilityParams = props.dataTables.flatMap((x) =>
            x.views.map(
                (y): DataTableAndViewVisibilityParams => ({
                    tableId: x.id,
                    tableViewId: y.id,
                    isVisible: y.isVisible,
                    name: y.name,
                })
            )
        );

        setDataTableVisibility(dataTableVisibilityParams);
        setDataTableViewVisibility(dataTableViewVisibilityParams);
        setIsDataTableAvailable(!isDataTableExist());
        setIsDataTablesVisible(
            isDataTableExist() &&
                isAnyDataTableVisible(dataTableViewVisibilityParams)
        );
    }, [props]);

    const updateStageVisibility = (formId: number, isVisible: boolean) => {
        const targetStage = questionnaireStagesVisibility.find(
            (stage) => stage.id === formId
        );
        const stageIndex = questionnaireStagesVisibility.indexOf(targetStage);

        const updatedStagesVisibility = [...questionnaireStagesVisibility];
        updatedStagesVisibility[stageIndex] = {
            ...targetStage,
            isVisible: isVisible,
        };

        setQuestionnaireStagesVisibility(updatedStagesVisibility);
        setIsQuestionnaireDataFormVisible(
            isAnyQuestionnaireDataFormsVisible(updatedStagesVisibility)
        );
    };

    const updateTableVisibility = (
        tableId: number,
        isVisible: boolean,
        forceUpdateViews: boolean
    ) => {
        const targetStage = dataTableVisibility.find(
            (stage) => stage.tableId === tableId
        );
        const stageIndex = dataTableVisibility.indexOf(targetStage);

        const updatedStagesVisibility = [...dataTableVisibility];
        updatedStagesVisibility[stageIndex] = {
            ...targetStage,
            isVisible: isVisible,
        };
        setDataTableVisibility(updatedStagesVisibility);
        setIsDataTablesVisible(isAnyDataTableVisible(updatedStagesVisibility));
        if (forceUpdateViews) {
            const allTableViewsByTableId = dataTableViewVisibility.filter(
                (x) => x.tableId === targetStage.tableId
            );

            const tableViewIds = allTableViewsByTableId.map(
                (x) => x.tableViewId
            );
            updateTableViewsVisibility(tableViewIds, isVisible);
        }
    };

    const updateTableViewsVisibility = (
        tableViewIds: number[],
        isVisible: boolean
    ) => {
        const updatedStagesVisibility = dataTableViewVisibility.map((stage) => {
            if (tableViewIds.includes(stage.tableViewId)) {
                return { ...stage, isVisible: isVisible };
            }
            return stage;
        });

        setDataTableViewVisibility(updatedStagesVisibility);
    };

    const updateTableViewVisibility = (
        tableViewId: number,
        isVisible: boolean
    ) => {
        const targetStage = dataTableViewVisibility.find(
            (stage) => stage.tableViewId === tableViewId
        );
        const stageIndex = dataTableViewVisibility.indexOf(targetStage);

        const updatedStagesVisibility = [...dataTableViewVisibility];
        updatedStagesVisibility[stageIndex] = {
            ...targetStage,
            isVisible: isVisible,
        };

        setDataTableViewVisibility(updatedStagesVisibility);
        const allTableViewsByTableId = updatedStagesVisibility.filter(
            (x) => x.tableId === targetStage.tableId
        );

        const tableStage = dataTableVisibility.find(
            (x) => x.tableId === targetStage.tableId
        );

        if (allTableViewsByTableId.some((x) => x.isVisible)) {
            if (!tableStage?.isVisible) {
                updateTableVisibility(targetStage.tableId, true, false);
            }
        } else {
            if (tableStage?.isVisible) {
                updateTableVisibility(targetStage.tableId, false, false);
            }
        }
    };

    const isAnyQuestionnaireDataFormsVisible = (
        stagesVisibility: QuestionnaireStageVisibilityParams[]
    ): boolean => {
        return stagesVisibility.some((x) => x.isVisible);
    };

    const isAnyDataTableVisible = (
        tablesVisibility: DataTableAndViewVisibilityParams[]
    ): boolean => {
        return tablesVisibility.some((x) => x.isVisible);
    };

    const getFilteredTemplateOptions = (
        templateForms: DataForm[],
        targetFormType: DataFormType
    ) => {
        const result = templateForms
            .map((obj: DataForm) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: obj.formType,
            }))
            .filter((x) => x.formType === targetFormType)
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));

        return result;
    };

    const getMappedDataTableTemplateOptions = (
        templateTables: DataTable[]
    ): TemplateDropdownOption[] => {
        const result = templateTables
            .map((obj: DataTable) => ({
                id: obj.id,
                name: obj.originalTemplateName,
                formType: undefined,
            }))
            .sort((a, b) => Helpers.stringSortFunction(a.name, b.name));

        return result;
    };

    const getTemplateOptions = () => {
        axios
            .get('/api/dataRequestTemplateProject/dataForms')
            .then((response) => {
                if (response.status === 200) {
                    const filteredDocumentTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.Documents
                        );

                    setDocumentTemplateOptions(filteredDocumentTemplateOptions);

                    const filteredFinancalRequestsTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.FinancialRequest
                        );

                    setFinancialRequestsTemplateOptions(
                        filteredFinancalRequestsTemplateOptions
                    );

                    const filteredQuestionnaireTemplateOptions =
                        getFilteredTemplateOptions(
                            response.data,
                            DataFormType.Questionnaire
                        );

                    setQuestionnaireTemplateOptions(
                        filteredQuestionnaireTemplateOptions
                    );

                    const questionnaireForms = response.data.filter(
                        (x: DataForm) =>
                            x.formType === DataFormType.Questionnaire
                    );
                    setQuestionnaireForms(questionnaireForms);
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .finally();
        axios
            .get('/api/dataRequestTemplateProject/dataTables')
            .then((response) => {
                if (response.status === 200) {
                    const filteredDataTableTemplateOptions =
                        getMappedDataTableTemplateOptions(response.data);

                    setDataTableTemplateOptions(
                        filteredDataTableTemplateOptions
                    );
                    setDataTables(response.data);
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            });
    };

    const handleDocumentTemplateOptionChange = (templateId: number) => {
        setSelectedDocumentTemplateId(templateId);
    };

    const handleFinancialRequestsTemplateOptionChange = (
        templateId: number
    ) => {
        setSelectedFinancialRequestsTemplateId(templateId);
    };

    const handleQuestionnaireTemplateOptionChange = (templateId: number) => {
        const previousQuestionnaireId = selectedQuestionnaireTemplateId;
        setSelectedQuestionnaireTemplateId(templateId);
        updatePreselectedDataTables(templateId, previousQuestionnaireId);
        updatePreselectedDataTableViews(templateId, previousQuestionnaireId);
    };

    const getTablesByQuestionnaireId = (questionnaireId: number) => {
        const selectedQuestionnaire = questionnaireForms.find(
            (x) => x.id === questionnaireId
        );

        if (!selectedQuestionnaire) {
            return [];
        }
        const tableViewIds = selectedQuestionnaire.questions
            .filter((x) => x.tableViewId)
            .map((x) => x.tableViewId);
        const tableIds = dataTables
            .filter((table) =>
                table.views.some((view) => tableViewIds.includes(view.id))
            )
            .map((table) => table.id);
        const uniqueTableIds = Array.from(new Set(tableIds));
        return uniqueTableIds;
    };

    const getUpdatedDataFormState = (
        type: DataFormType,
        formName: string = ''
    ) => {
        let isDataFormVisible = false;
        let selectedTemplate = 0;

        switch (type) {
            case DataFormType.Documents:
                isDataFormVisible = isDocumentDataFormVisible;
                selectedTemplate = selectedDocumentTemplateId;
                break;
            case DataFormType.FinancialRequest:
                isDataFormVisible = isFinancialRequestsDataFormVisible;
                selectedTemplate = selectedFinancialRequestsTemplateId;
                break;
            case DataFormType.Questionnaire:
                isDataFormVisible = isQuestionnaireDataFormVisible;
                selectedTemplate = selectedQuestionnaireTemplateId;
                break;
        }

        const result = isDataFormExist(type)
            ? {
                  id: props.dataForms.find((x) => x.formType === type).id,
                  templateId: 0,
                  isVisible: isDataFormVisible,
                  formName: formName,
              }
            : isDataFormVisible
            ? {
                  id: 0,
                  templateId: selectedTemplate,
                  isVisible: isDataFormVisible,
                  formName: formName,
              }
            : null;
        return result;
    };

    const updateTableSettings = () => {
        setIsLoading(true);

        const documentForm = getUpdatedDataFormState(DataFormType.Documents);
        const financialRequestsForm = getUpdatedDataFormState(
            DataFormType.FinancialRequest
        );
        const questionnaireForm = getUpdatedDataFormState(
            DataFormType.Questionnaire,
            questionnaireFormName
        );

        let params = [];

        if (documentForm && (documentForm.id || documentForm.templateId)) {
            params.push(documentForm);
        }

        if (
            financialRequestsForm &&
            (financialRequestsForm.id || financialRequestsForm.templateId)
        ) {
            params.push(financialRequestsForm);
        }

        if (questionnaireStagesVisibility.length) {
            params.push(...questionnaireStagesVisibility);
        } else if (
            questionnaireForm &&
            (questionnaireForm.id || questionnaireForm.templateId)
        ) {
            params.push(questionnaireForm);
        }

        const shouldDataTablesBeCreated =
            selectedDataTableTemplateIds?.length > 0;

        const shouldDataFormsBeUpdated = params.length > 0;

        if (shouldDataTablesBeCreated) {
            createDataTable(shouldDataFormsBeUpdated, params);
        } else if (shouldDataFormsBeUpdated) {
            updateDataTableAndViewVisibleStatuses();
            updateDataFormVisibleStatuses(params);
        }
    };

    const updateDataTableAndViewVisibleStatuses = () => {
        let params = [];
        params.push(...dataTableVisibility);
        params.push(...dataTableViewVisibility);
        axios
            .put(
                `/api/DataRequestProjects/${props.projectId}/dataTableVisibleStatuses`,
                params
            )
            .then((response: any) => {
                if (response.status !== 200) {
                    createNotification(response.response.data.detail, 'error');
                }
            })
            .catch(() => {
                createNotification('Error occured while updating', 'error');
            });
    };

    const updateDataFormVisibleStatuses = (params: any[]) => {
        axios
            .put(
                `/api/DataRequestProjects/${props.projectId}/dataFormVisibleStatuses`,
                params
            )
            .then((response: any) => {
                if (response.status !== 200) {
                    createNotification(response.response.data.detail, 'error');
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while project creation',
                    'error'
                );
            })
            .finally(() => {
                props.setIsVisible(false);
                props.onSubmit();
                setIsLoading(false);
            });
    };

    const createDataTable = (
        shouldDataFormsBeUpdated: boolean,
        params: any[]
    ) => {
        let requestParams = {
            dataTableTemplateIds: selectedDataTableTemplateIds,
            dataTableViewTemplateIds: selectedDataTableViewTemplateIds ?? [],
        };
        if (!isDataTablesVisible) {
            requestParams = {
                dataTableTemplateIds: getTablesByQuestionnaireId(
                    selectedQuestionnaireTemplateId
                ),
                dataTableViewTemplateIds:
                    getTableViewsByQuestionnaireId(
                        selectedQuestionnaireTemplateId
                    ) ?? [],
            };
        }

        axios
            .post(
                `api/DataRequestProjects/${props.projectId}/dataTables`,
                requestParams
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while creating new table',
                        'error'
                    );
                } else if (shouldDataFormsBeUpdated) {
                    updateDataFormVisibleStatuses(params);
                }
            })
            .finally(() => {
                if (!shouldDataFormsBeUpdated) {
                    props.setIsVisible(false);
                    props.onSubmit();
                    setIsLoading(false);
                }
            });
    };

    const isDataFormExist = (type: DataFormType) => {
        return props.dataForms.some((x) => x.formType === type);
    };

    const isDataTableExist = () => {
        return props.dataTables.length > 0;
    };

    const isSubmitButtonDisabled = () => {
        const isDocumentTableSettingsValid =
            isDataFormExist(DataFormType.Documents) ||
            (isDocumentDataFormVisible
                ? selectedDocumentTemplateId != null
                : true);

        const isFinancialRequestsTableSettingsValid =
            isDataFormExist(DataFormType.FinancialRequest) ||
            (isFinancialRequestsDataFormVisible
                ? selectedFinancialRequestsTemplateId != null
                : true);

        const isQuestionnaireTableSettingsValid =
            isDataFormExist(DataFormType.Questionnaire) ||
            (isQuestionnaireDataFormVisible
                ? selectedQuestionnaireTemplateId != null
                : true);

        const isDataTableSettingsValid =
            isDataTableExist() ||
            (isDataTablesVisible
                ? selectedDataTableTemplateIds?.length > 0
                : true);

        return (
            !isDocumentTableSettingsValid ||
            !isFinancialRequestsTableSettingsValid ||
            !isQuestionnaireTableSettingsValid ||
            !isDataTableSettingsValid ||
            !isAnyFormSelected()
        );
    };

    const isAnyFormSelected = (): boolean =>
        isDocumentDataFormVisible ||
        isFinancialRequestsDataFormVisible ||
        isQuestionnaireDataFormVisible ||
        isDataTablesVisible;

    const renderAvailableStagesList = () => {
        return questionnaireStagesVisibility.map((stage) => (
            <div className="field-switcher">
                <ReactSwitch
                    offColor="#d92550"
                    checked={stage.isVisible}
                    onChange={(event) => {
                        updateStageVisibility(stage.id, event);
                    }}
                    height={22}></ReactSwitch>
                <div className="field-label">{stage.formName}</div>
            </div>
        ));
    };

    const renderDataTableList = () => {
        return dataTableVisibility.map((table) => (
            <div className="table-visibility-section">
                <div className="field-switcher">
                    <ReactSwitch
                        offColor="#d92550"
                        checked={table.isVisible}
                        onChange={(event) => {
                            updateTableVisibility(table.tableId, event, true);
                        }}
                        height={22}></ReactSwitch>
                    <div className="field-label">{table.name}</div>
                </div>
                <div className="table-view-visibility-section ml-56">
                    {dataTableViewVisibility
                        .filter((x) => x.tableId === table.tableId)
                        .map((tableView) => (
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={tableView.isVisible}
                                    onChange={(event) => {
                                        updateTableViewVisibility(
                                            tableView.tableViewId,
                                            event
                                        );
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    {tableView.name}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        ));
    };

    const updatePreselectedDataTables = (
        templateId: number,
        previousQuestionnaireId: number
    ) => {
        const uniqueTableIds = getTablesByQuestionnaireId(templateId);

        const uniquePreviousTableIds = getTablesByQuestionnaireId(
            previousQuestionnaireId
        );

        let updatedTables = (selectedDataTableTemplateIds ?? []).filter(
            (id) => !uniquePreviousTableIds.includes(id)
        );
        uniqueTableIds.forEach((id) => {
            if (!updatedTables.includes(id)) {
                updatedTables.push(id);
            }
        });
        setSelectedDataTableTemplateIds(updatedTables);
    };

    const updatePreselectedDataTableViews = (
        templateId: number,
        previousQuestionnaireId: number
    ) => {
        const uniqueTableViewIds = getTableViewsByQuestionnaireId(templateId);

        const uniquePreviousTableViewIds = getTableViewsByQuestionnaireId(
            previousQuestionnaireId
        );

        let updatedTableViews = (selectedDataTableViewTemplateIds ?? []).filter(
            (id) => !uniquePreviousTableViewIds.includes(id)
        );
        uniqueTableViewIds.forEach((id) => {
            if (!updatedTableViews.includes(id)) {
                updatedTableViews.push(id);
            }
        });

        setSelectedDataTableViewTemplateIds(updatedTableViews);
    };

    const getTableViewsByQuestionnaireId = (questionnaireId: number) => {
        const selectedQuestionnaire = questionnaireForms.find(
            (x) => x.id === questionnaireId
        );

        if (!selectedQuestionnaire) {
            return [];
        }
        const tableViewIds = selectedQuestionnaire.questions
            .filter((x) => x.tableViewId)
            .map((x) => x.tableViewId);
        const uniqueTableViewIds = Array.from(new Set(tableViewIds));
        return uniqueTableViewIds;
    };

    const handleDataTableTemplateOptionsChange = (templateIds: number[]) => {
        setSelectedDataTableTemplateIds(templateIds);
    };

    const getTableMultiselectOptions = () => {
        return dataTableTemplateOptions.map((x) => {
            return { id: x.id, option: x.name };
        });
    };

    const getDataTableViewMultiselectOptions = () => {
        const tables = dataTables.filter((table) =>
            selectedDataTableTemplateIds?.includes(table.id)
        );
        const tableViews = tables.flatMap((x) => x.views);

        return tableViews.map((view) => {
            const table = tables.find((table) =>
                table.views.some((v) => v.id === view.id)
            );
            const name = `${table?.name} - ${view.name}`;

            return { id: view.id, option: name };
        });
    };

    const handleDataTableViewTemplateOptionsChange = (
        templateIds: number[]
    ) => {
        setSelectedDataTableViewTemplateIds(templateIds);
    };

    const renderDataTableField = () => {
        return (
            <CreateDataTableForm
                isDataTablesVisible={isDataTablesVisible}
                setIsDataTablesVisible={setIsDataTablesVisible}
                getTableMultiselectOptions={getTableMultiselectOptions}
                selectedDataTableTemplateIds={selectedDataTableTemplateIds}
                handleDataTableTemplateOptionsChange={
                    handleDataTableTemplateOptionsChange
                }
                getDataTableViewMultiselectOptions={
                    getDataTableViewMultiselectOptions
                }
                selectedDataTableViewTemplateIds={
                    selectedDataTableViewTemplateIds
                }
                handleDataTableViewTemplateOptionsChange={
                    handleDataTableViewTemplateOptionsChange
                }
                getTableViewsByQuestionnaireId={() => {
                    return getTableViewsByQuestionnaireId(
                        selectedQuestionnaireTemplateId
                    );
                }}
                getTablesByQuestionnaireId={() => {
                    return getTablesByQuestionnaireId(
                        selectedQuestionnaireTemplateId
                    );
                }}
                setSelectedDataTableViewTemplateIds={
                    setSelectedDataTableViewTemplateIds
                }
                setSelectedDataTableTemplateIds={
                    setSelectedDataTableTemplateIds
                }></CreateDataTableForm>
        );
    };

    return (
        <div className="table-settings">
            <Rodal
                visible={props.isVisible}
                onClose={() => {
                    props.setIsVisible(false);
                }}
                width={500}
                className="checkout-modal"
                animation={'fade'}
                showMask={false}
                centered>
                <ModalHeader>Settings</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col
                            md={
                                isDataFormExist(DataFormType.Documents) ? 12 : 6
                            }>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isDocumentDataFormVisible}
                                    onChange={(event) => {
                                        setIsDocumentDataFormVisible(event);
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    Document Template
                                </div>
                            </div>
                        </Col>
                        {!isDataFormExist(DataFormType.Documents) && (
                            <Col md={6}>
                                <DropdownList
                                    selectIcon={<ArrowDown />}
                                    disabled={!isDocumentDataFormVisible}
                                    data={documentTemplateOptions}
                                    defaultValue={documentTemplateOptions[0]}
                                    onChange={(value) => {
                                        handleDocumentTemplateOptionChange(
                                            value.id
                                        );
                                    }}
                                    dataKey="id"
                                    filter="contains"
                                    textField="name"
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col
                            md={
                                isDataFormExist(DataFormType.FinancialRequest)
                                    ? 12
                                    : 6
                            }>
                            <div className="field-switcher">
                                <ReactSwitch
                                    offColor="#d92550"
                                    checked={isFinancialRequestsDataFormVisible}
                                    onChange={(event) => {
                                        setIsFinancialRequestsDataFormVisible(
                                            event
                                        );
                                    }}
                                    height={22}></ReactSwitch>
                                <div className="field-label">
                                    Financial Requests Template
                                </div>
                            </div>
                        </Col>
                        {!isDataFormExist(DataFormType.FinancialRequest) && (
                            <Col md={6}>
                                <DropdownList
                                    selectIcon={<ArrowDown />}
                                    disabled={
                                        !isFinancialRequestsDataFormVisible
                                    }
                                    data={financialRequestsTemplateOptions}
                                    defaultValue={
                                        financialRequestsTemplateOptions[0]
                                    }
                                    onChange={(value) => {
                                        handleFinancialRequestsTemplateOptionChange(
                                            value.id
                                        );
                                    }}
                                    dataKey="id"
                                    filter="contains"
                                    textField="name"
                                />
                            </Col>
                        )}
                    </Row>
                    {isDataTableAvailable ? (
                        <>{renderDataTableField()}</>
                    ) : (
                        <Col>
                            <Row className="section-header">Data Tables</Row>
                            <Row className="stages-settings-list">
                                {renderDataTableList()}
                            </Row>
                        </Col>
                    )}
                    <Col>
                        <Row className="section-header">
                            Questionnaire Stages
                        </Row>
                        {!isDataFormExist(DataFormType.Questionnaire) ? (
                            <Row className="add-first-stage-container">
                                <Col md={4}>
                                    <Row>
                                        <ReactSwitch
                                            offColor="#d92550"
                                            checked={
                                                isQuestionnaireDataFormVisible
                                            }
                                            onChange={(event) => {
                                                setIsQuestionnaireDataFormVisible(
                                                    event
                                                );
                                            }}
                                            height={22}></ReactSwitch>
                                    </Row>
                                </Col>
                                <Col
                                    md={8}
                                    className="questionnaire-section-input">
                                    <DropdownList
                                        selectIcon={<ArrowDown />}
                                        disabled={
                                            !isQuestionnaireDataFormVisible
                                        }
                                        data={questionnaireTemplateOptions}
                                        defaultValue={
                                            questionnaireTemplateOptions[0]
                                        }
                                        onChange={(value) => {
                                            handleQuestionnaireTemplateOptionChange(
                                                value.id
                                            );
                                        }}
                                        dataKey="id"
                                        filter="contains"
                                        textField="name"
                                    />
                                    <Input
                                        disabled={
                                            !isQuestionnaireDataFormVisible
                                        }
                                        placeholder="Optional..."
                                        onChange={(event) => {
                                            setQuestionnaireFormName(
                                                event.target.value.trim()
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Row className="stages-settings-list">
                                {renderAvailableStagesList()}
                            </Row>
                        )}
                    </Col>

                    <Row className="mb-0">
                        <Col className="button-container">
                            <Button
                                type="button"
                                className="btn btn-cancel"
                                disabled={isLoading}
                                onClick={() => {
                                    props.setIsVisible(false);
                                }}>
                                Cancel & Close
                            </Button>
                            <ButtonLoader
                                buttonText={`Save & Close`}
                                loaderButtonText={''}
                                disabled={isSubmitButtonDisabled()}
                                isLoading={isLoading}
                                onClick={() => {
                                    updateTableSettings();
                                }}
                                className={
                                    'btn btn-primary loader-submit'
                                }></ButtonLoader>
                        </Col>
                    </Row>
                </ModalBody>
            </Rodal>
        </div>
    );
};
